import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"four-oh-four mt-10 mt-md-16"},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c('h1',{staticClass:"four-oh-four__header mb-4"},[_vm._v("404: Not Found")])]),_c(VRow,{staticClass:"mx-4",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VImg,{staticClass:"four-oh-four__image mx-4",attrs:{"src":_vm.logo,"alt":"Spencer Krips Music"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1),_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VBtn,{staticClass:"four-oh-four__button mt-10 mt-md-16",attrs:{"tile":"","large":"","color":"#8a4d58","to":{
        name: 'Home',
      }}},[_vm._v("Go back to safety")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }