


































import Vue from "vue";
import logo from "@/assets/error/spencer-krips-logo.png";

export default Vue.extend({
  name: "FourOhFour",
  data: () => ({
    logo,
  }),
});
